<template>
  <section class="confirm-details">
    <div class="flex__between">
      <h3 class="text__center">Recipient Info</h3>
      <span
        class="text__red text__bold pointer"
        @click="toggleEditing"
      >{{ editing ? "Cancel" : "Edit" }}</span>
    </div>
    <p v-if="editing">You must click "submit" below to save any changes made to this data.</p>
    <label>
      Recipient Type
      <span v-if="!editing">{{ active.recipientType }}</span>
      <select v-else v-model="form.recipientType">
        <option value="Borrower">Borrower</option>
        <option value="Referral Partner">Referral Partner</option>
        <option value="Internal Employee">Internal Employee</option>
      </select>
    </label>
    <!-- <label
      v-if="form.recipientType !== 'Internal Employee'"
      :class="[errors.loanNumber ? 'form--error' : null]"
    >
      Loan Number{{ form.recipientType === "Borrower" ? "*" : null }}
      <span
        v-if="!editing"
      >{{ active.loanNumber }}</span>
      <input
        v-else
        type="text"
        v-mask="'##########'"
        v-model="form.loanNumber"
        placeholder="1234567890"
      />
    </label> -->
    <form-error v-if="editing" :message="errors.loanNumber" />
    <label :class="[errors.recipientName ? 'form--error' : null]">
      Recipient Name*
      <span v-if="!editing">{{ active.recipientName }}</span>
      <input v-else type="text" v-model="form.recipientName" placeholder="first and last" />
    </label>
    <form-error v-if="editing" :message="errors.recipientName" />
    <label>
      Co Recipient Name
      <span v-if="!editing">{{ active.coRecipientName }}</span>
      <input v-else type="text" v-model="form.coRecipientName" placeholder="first and last" />
    </label>
    <label
      v-if="form.recipientType !== 'Internal Employee'"
      :class="[errors.milestone ? 'form--error' : null]"
    >
      Milestone{{ form.recipientType === "Borrower" ? "*" : null }}
      <span
        v-if="!editing"
      >{{ active.milestone }}</span>
      <select v-else v-model="form.milestone">
        <option value></option>
        <option value="Application">Application</option>
        <option value="Under Contract">Under Contract</option>
        <option value="Closing">Closing</option>
        <option v-if="form.recipientType === 'Referral Partner'" value="Referral">Referral</option>
      </select>
    </label>
    <form-error v-if="editing" :message="errors.milestone" />
    <div class="flex__between" v-if="editing">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Submit</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["active"],
  data() {
    return {
      editing: false,
      isValidated: true,
      form: {
        _id: this.active ? this.active._id : null,
        recipientType: this.active ? this.active.recipientType : null,
        loanNumber: this.active ? this.active.loanNumber : null,
        recipientName: this.active ? this.active.recipientName : null,
        coRecipientName: this.active ? this.active.coRecipientName : null,
        milestone: this.active ? this.active.milestone : null
      },
      errors: {
        loanNumber: null,
        recipientName: null,
        milestone: null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateOrder"]),
    async submit() {
      // if (!this.canEdit) return;
      this.validateForm();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Submitting" });
      try {
        await this.startUpdateOrder(this.form);
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      // if (this.form.recipientType === "Borrower") {
      //   this.errors.loanNumber =
      //     this.form.loanNumber.length < 10
      //       ? "Please enter a full loan number"
      //       : null;
      // } else {
      //   this.errors.loanNumber = null;
      // }
      this.errors.recipientName =
        this.form.recipientName === ""
          ? "Please enter a name for the recipient"
          : null;
      this.errors.milestone =
        this.form.recipientType === "Borrower" && this.form.milestone === ""
          ? "Please select a milestone"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    toggleEditing() {
      this.editing = !this.editing;
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-details {
  background-color: lighten($frost, 5%);
  padding: 5px;
  border: 1px solid $sky;
  border-radius: 3px;
}
</style>