<template>
  <section class="modal" @click.self="unsetActiveOrder">
    <section class="bulkitem-sidebar shadow pos__rel">
      <div class="bulkitem-sidebar--exit flex__center pointer" @click="unsetActiveOrder">
        <font-awesome-icon icon="times" size="lg" />
      </div>
      <delete v-if="deleting">
        <DeleteBulkItem
          v-if="deleting"
          :active="active"
          :activeBulk="activeBulk"
          @deleted="unsetActiveOrder"
          @toggleDeleting="toggleDeleting"
        />
      </delete>
      <simplebar class="sidebar--overflow" data-simplebar-auto-hide="false">
        <div class="bulkitem-sidebar__grid">
          <div class="flex__between">
            <h2>Selected Order</h2>
            <span class="text__bold text__red pointer" @click="toggleDeleting">Delete</span>
          </div>
          <Recipient :active="active" />
          <Contact :active="active" />
          <Products :active="active" />
          <FinalDetails :active="active" />
        </div>
      </simplebar>
    </section>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Recipient from "./Recipient";
import Contact from "./Contact";
import Products from "./Products";
import FinalDetails from "./FinalDetails";
import DeleteBulkItem from "../DeleteBulkItem";
export default {
  props: ["active", "activeBulk"],
  components: {
    Recipient,
    Contact,
    Products,
    FinalDetails,
    DeleteBulkItem
  },
  data() {
    return {
      deleting: false
    };
  },
  methods: {
    ...mapActions(["startUnsetActiveOrder"]),
    unsetActiveOrder() {
      this.startUnsetActiveOrder(null);
    },
    toggleDeleting() {
      this.deleting = !this.deleting;
    }
  }
};
</script>

<style scoped lang="scss">
$dark-frost: darken($frost, 35%);
.bulkitem-sidebar {
  @include pos-abs(
    $pos: fixed,
    $top: 0,
    $left: null,
    $right: 0,
    $width: calc(100% - 40px),
    $height: 100vh,
    $z: 15
  );
  max-width: 450px;
  background-color: $sky;
  padding: 25px;
  padding-right: 15px;
}
.bulkitem-sidebar--exit {
  @include pos-abs($pos: absolute, $top: 3px, $left: null, $right: 100%);
  background-color: $dark-frost;
  padding: 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: $sky;
}
.sidebar--overflow {
  max-height: calc(100% - 10px);
}
.bulkitem-sidebar__grid {
  @include grid($cols: 1fr, $row-gap: 20px);
  padding-right: 20px;
}
</style>