<template>
  <li class="confirm-products--item">
    <div class="confirm-products--list-item pos__rel tile">
      <div class="confirm-products__grid">
        <img class="confirm--img" :src="productImg" />
        <p class="confirm--name text__bold text__md">{{ item.productName }}</p>
        <p class="order-row flex__left">
          <span class="text__bold mr-10">Qty:</span>
          <span v-if="!editing">{{ item.quantity }}</span>
          <select v-else v-model="form.quantity">
            <option v-for="num in 25" :key="num" :value="num">{{ num }}</option>
          </select>
        </p>
        <p>
          <span class="text__bold mr-10">Total Cost:</span>
          <span
            class="text__bold text__pine text__md"
          >${{ (item.quantity * item.loCost).toFixed(2) }}</span>
        </p>
        <div class="cust--list-container">
          <div class="text__bold flex__between">
            <span>Customizations</span>
            <hr class="ml-10" />
          </div>
          <simplebar class="cust--overflow" data-simplebar-auto-hide="false">
            <div
              v-if="!item.customizations || item.customizations.length <= 0"
              class="flex__col bg-light-grey padding__sm"
            >
              <p class="text__bold text__center">No customizations for this product</p>
            </div>
            <ul v-else class="cust--list">
              <li v-for="cust in item.customizations" :key="cust._id">
                <span class="text__bold">{{ cust.name }}:</span>
                <span class="ml-10 pre-wrap">{{ cust.option }}</span>
              </li>
            </ul>
          </simplebar>
        </div>
      </div>
    </div>
    <div v-if="!deleting && !editing" class="dual--buttons">
      <div class="btn__green flex__center" @click="toggleEditing">
        <font-awesome-icon icon="edit" size="1x" class="mr-10" />Edit
      </div>
      <div class="btn__red flex__center" @click="toggleDelete">
        <font-awesome-icon icon="times" size="1x" class="mr-10" />Delete
      </div>
    </div>
    <div v-if="editing" class="dual--buttons">
      <div class="btn__green flex__center" @click="toggleEditing">Cancel</div>
      <div class="btn__red flex__center" @click="updateOrderItem">Submit</div>
    </div>
    <div v-if="deleting" class="dual--buttons">
      <div class="btn__green flex__center" @click="deleteOrderItem">Confirm Delete</div>
      <div class="btn__red flex__center" @click="toggleDelete">Cancel</div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      deleting: false,
      editing: false,
      form: {
        _id: this.item ? this.item._id : null,
        quantity: this.item ? this.item.quantity : 1
      }
    };
  },
  computed: {
    ...mapGetters(["getProducts", "getUserDetails"]),
    productImg() {
      return this.getProducts.filter(
        product => product._id === this.item.product
      )[0].images[0];
    }
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startUpdateOrderItem",
      "startDeleteOrderItem"
    ]),
    toggleDelete() {
      this.deleting = !this.deleting;
    },
    toggleEditing() {
      this.editing = !this.editing;
    },
    async updateOrderItem() {
      try {
        this.startSetIsSubmitting({ bool: true, heading: "Updating" });
        await this.startUpdateOrderItem(this.form);
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    async deleteOrderItem() {
      try {
        this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
        await this.startDeleteOrderItem(this.item);
        this.toggleDeleting();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-products--item {
  @include grid($cols: 1fr, $rows: auto 35px);
}
.confirm-products--list-item {
  background-color: lighten($sky, 5%);
  border: 1px solid $sky;
  border-bottom: none;
}
.confirm-products__grid {
  @include grid($cols: 1fr, $row-gap: 8px);
}
.confirm--img {
  width: 75px;
  height: 75px;
  margin: auto;
}
.confirm--name {
  line-height: 1.2rem;
}
.cust--overflow {
  height: 125px;
  border: 1px solid $black;
}
.bg-light-grey {
  height: 123px;
}
.cust--list {
  @include grid($cols: 1fr, $row-gap: 8px);
  padding: 5px 10px;
  li {
    @include flex($just: space-between, $align: flex-start, $wrap: wrap);
    span:nth-child(2) {
      text-align: right;
    }
    font-size: 0.9rem;
  }
}
.dual--buttons {
  height: 35px;
}
</style>