<template>
  <section class="container bulkorders-container">
    <Header
      :active="getActiveBulkOrder"
      @toggleDeleting="toggleDeleting"
      @toggleExiting="toggleExiting"
    />
    <Admin
      :active="getActiveBulkOrder"
      :bulkItems="getBulkItems"
      @toggleImport="toggleImport"
      @toggleProducts="toggleProducts"
      @toggleConfirm="toggleConfirm"
    />
    <BatchImport v-if="importToggled" :active="getActiveBulkOrder" @toggleImport="toggleImport" />
    <!-- <Summary :bulk="getActiveBulkOrder" :items="getBulkItems" /> -->
    <!-- <simplebar class="batch--overflow" data-simplebar-auto-hide="false"> -->
    <Orders :bulkItems="getBulkItems" :bulk="getActiveBulkOrder" @toggleImport="toggleImport" />
    <!-- </simplebar> -->
    <transition name="slide">
      <Sidebar
        v-if="getActiveOrder && !deleteToggled"
        :active="getActiveOrder"
        :activeBulk="getActiveBulkOrder"
        :key="getActiveOrder._id"
      />
    </transition>
    <transition name="slide">
      <Products v-if="productsToggled" @toggleProducts="toggleProducts" />
    </transition>
    <Confirm
      v-if="confirmToggled"
      :active="getActiveBulkOrder"
      :orders="getBulkItems"
      :canSubmit="canSubmit"
      @toggleConfirm="toggleConfirm"
    />
    <delete v-if="deleteToggled">
      <DeleteBulkOrder :active="getActiveBulkOrder" @toggleDeleting="toggleDeleting" />
    </delete>
    <exit v-if="exitToggled">
      <ExitBulkOrder @toggleExiting="toggleExiting" />
    </exit>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "./Header";
import Admin from "./Admin";
import BatchImport from "./BatchImport";
// import Summary from "./Summary";
import Orders from "./Orders";
import Sidebar from "./Sidebar";
import Products from "./Products";
import Confirm from "./Confirm";
import DeleteBulkOrder from "./DeleteBulkOrder";
import ExitBulkOrder from "./ExitBulkOrder";
export default {
  components: {
    Header,
    Admin,
    BatchImport,
    // Summary,
    Orders,
    Sidebar,
    Products,
    Confirm,
    DeleteBulkOrder,
    ExitBulkOrder
  },
  data() {
    return {
      deleteToggled: false,
      exitToggled: false,
      importToggled: false,
      productsToggled: false,
      confirmToggled: false
    };
  },
  metaInfo: {
    title: "Bulk Order Items | WOW"
  },
  computed: {
    ...mapGetters([
      "getActiveBulkOrder",
      "getUserDetails",
      "getBulkItems",
      "getActiveOrder"
    ]),
    canSubmit() {
      if (
        !this.getActiveBulkOrder.bulkItems ||
        this.getActiveBulkOrder.bulkItems.length <= 0
      ) {
        return false;
      }
      return this.getBulkItems
        .map(item => {
          return this.isValid(item).length <= 0;
        })
        .every(bool => bool);
    }
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startCreateBatchOrder",
      "startUpdateBulkOrder",
      "startUnsetActiveOrder"
    ]),
    toggleImport() {
      this.importToggled = !this.importToggled;
    },
    toggleDeleting() {
      this.deleteToggled = !this.deleteToggled;
    },
    toggleExiting() {
      this.exitToggled = !this.exitToggled;
    },
    toggleProducts() {
      this.productsToggled = !this.productsToggled;
    },
    toggleConfirm() {
      this.confirmToggled = !this.confirmToggled;
    },
    isValid(order) {
      let isValid = [];
      order.billingMethod === "" || !order.billingMethod
        ? isValid.push("Add billing method")
        : null;
      order.billingUser === "" || !order.billingUser
        ? isValid.push("Add billing user")
        : null;
      order.orderItems.filter(item => item.isInventoried).length > 0 &&
      order.thankYouNote.length <= 0
        ? isValid.push("Add thank you")
        : null;
      !order.orderItems || order.orderItems.length <= 0
        ? isValid.push("Add products")
        : null;
      return isValid;
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorders-container {
  margin-top: 15px;
  margin-bottom: 100px;
}
.batch--overflow {
  min-height: 450px;
  max-height: 750px;
}
$slide-speed: 0.25s;
.slide-enter {
  transform: translateX(100%);
}
.slide-enter-to {
  transform: translateX(0%);
  transition: transform $slide-speed ease-in-out;
}
.slide-leave {
  transform: translateX(0%);
}
.slide-leave-to {
  transform: translateX(100%);
  transition: transform $slide-speed ease-in-out;
}
@media (min-width: $lg) {
  .bulkorders-container {
    max-width: 750px;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 100px;
  }
}
</style>