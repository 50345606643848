<template>
  <section class="margin__md">
    <h2>Products</h2>
    <section class="confirm-products">
      <ul class="confirm-products--list">
        <li
          v-if="!active.orderItems || active.orderItems.length <= 0"
          class="padding__md flex__center"
        >
          <span class="text__bold">No products selected</span>
        </li>
        <Product b-else v-for="item in active.orderItems" :key="item._id" :item="item" />
        <Add :activeOrder="active" />
      </ul>
    </section>
  </section>
</template>

<script>
import Add from "../Add";
import Product from "./Product";
export default {
  props: ["active"],
  components: {
    Add,
    Product
  }
};
</script>

<style scoped lang="scss">
.confirm-products--list {
  @include grid($cols: 1fr, $col-gap: 25px, $row-gap: 25px);
}
</style>