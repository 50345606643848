<template>
  <li class="confirm-products--add">
    <div class="confirm-products--new" @click="toggleIsOpen">
      <font-awesome-icon v-if="!isOpen" icon="plus" size="1x" />
      <font-awesome-icon v-else icon="minus" size="1x" />
      <p class="text__bold">Add Product</p>
    </div>
    <transition name="slide-vert">
      <div v-if="isOpen" class="confirm-products--add__open">
        <div v-if="!productSelected">
          <div class="search--bar mb-15">
            <input type="text" v-model="search" placeholder="search" />
            <div class="btn__search">
              <font-awesome-icon icon="search" size="1x" />
            </div>
          </div>
          <simplebar class="add--overflow" data-simplebar-auto-hide="false">
            <ul class="add-product">
              <Product
                v-for="product in filteredProducts"
                :key="product._id"
                :product="product"
                @toggleProductSelected="toggleProductSelected"
              />
            </ul>
          </simplebar>
        </div>
        <Cust
          v-if="productSelected"
          @toggleProductSelected="toggleProductSelected"
          :active="activeProduct"
          :activeOrder="activeOrder"
        />
      </div>
    </transition>
  </li>
</template>

<script>
import { mapGetters } from "vuex";
import Product from "./Product";
import Cust from "./Cust";
export default {
  props: ["activeOrder"],
  components: {
    Product,
    Cust
  },
  data() {
    return {
      isOpen: false,
      productSelected: false,
      search: "",
      activeProduct: null
    };
  },
  computed: {
    ...mapGetters(["getLiveProducts"]),
    filteredProducts() {
      if (this.search === "") return this.getLiveProducts;
      return this.getLiveProducts.filter(
        product =>
          product.name.toLowerCase().includes(this.search.toLowerCase()) ||
          product.tags.join(", ").includes(this.search.toLowerCase())
      );
    }
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
    toggleProductSelected(product) {
      this.productSelected = !this.productSelected;
      this.setActiveProduct(product);
    },
    setActiveProduct(product) {
      this.activeProduct = product;
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-products--add {
  @include grid($cols: 1fr, $row-gap: 8px);
}
.confirm-products--new {
  @include grid($cols: 25px 1fr, $col-gap: 10px, $just: center, $align: center);
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px dashed $sky;
  background-color: lighten($frost, 10%);
}
.confirm-products--add__open {
  height: 425px;
  overflow: hidden;
}
.add--overflow {
  height: 370px;
  padding: 10px 5px;
}
.add-product {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 15px);
}
.slide-vert-enter {
  height: 0px;
}
.slide-vert-enter-to {
  height: 425px;
  transition: height 0.25s ease-in-out;
}
.slide-vert-leave {
  height: 425px;
}
.slide-vert-leave-to {
  height: 0px;
  transition: height 0.25s ease-in-out;
}
@media (min-width: $sm) {
  .add-product {
    grid-template-columns: 1fr 1fr;
  }
}
</style>