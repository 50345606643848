<template>
  <div class="modal flex__center" @click.self="emitToggleImport">
    <div class="bulkorders-import pos__rel">
      <exit-button @toggleModal="emitToggleImport" />
      <h2 class="flex__center">Batch Import</h2>
      <a
        href="https://cdn.fbhl.com/WOW-Assets/data/bulk-import-8-1-21.xlsx"
        target="_blank"
        name="download the import spreadsheet"
        rel="nofollow"
        class="bulkorders-import--step link__none"
      >
        <div class="import-number">1</div>
        <p class="text__bold text__md">
          Download Template
          <font-awesome-icon icon="file-download" size="1x" class="ml-10" />
        </p>
        <p>Click here to download the template to your device.</p>
      </a>
      <div class="bulkorders-import--step">
        <div class="import-number">2</div>
        <p class="text__bold text__md">Input Data</p>
        <p>Input any applicable order data and save as a CSV (comma separated value) file.</p>
      </div>
      <label class="bulkorders-import--step pointer">
        <div class="import-number">3</div>
        <p class="text__bold text__md">
          Upload Template
          <font-awesome-icon icon="file-upload" size="1x" class="ml-10" />
        </p>
        <p>Click here to search for and select your import sheet.</p>
        <input type="file" @change="readFile" id="csvimport" accept=".csv" />
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["active"],
  data() {
    return {
      data: null,
      config: {
        delimiter: "",
        newline: "",
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        dynamicTyping: true,
        preview: 1,
        encoding: "",
        delimitersToGuess: [",", "\t", "|", ";"],
        skipEmptyLines: "greedy"
      }
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"])
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startUpdateBatchProducts",
      "startUpdateBulkOrder",
      "startCreateBatchOrder"
    ]),
    emitToggleImport() {
      this.$emit("toggleImport");
    },
    readFile(evt) {
      this.data = null;
      const file = evt.target.files[0];
      document.querySelector("#csvimport").value = "";
      const that = this;
      this.$papa.parse(file, {
        header: true,
        complete: function(results) {
          const data = results.data
            .filter(row => row.recipientName)
            .map(row => {
              delete row[""];
              row.requester = that.getUserDetails._id;
              row.requesterName = that.getUserDetails.displayName;
              row.requesterEmail = that.getUserDetails.email;
              row.requester2Email = that.getUserDetails.email2;
              row.bulkOrder = that.active._id;
              row.shippingAddress = {
                street: row.street,
                city: row.city,
                state: row.state,
                zip: row.zip
              };
              row.billingUser = that.active.billingUser;
              row.billingName = that.active.billingName;
              row.billingEmail = that.active.billingEmail;
              row.thankYouNote = that.active.thankYouNote;
              row.billingMethod = that.active.billingMethod;
              row.comments = that.active.comments;
              delete row.street;
              delete row.city;
              delete row.state;
              delete row.zip;
              return row;
            });
          that.data = data;
          if (data.length <= 0) return;
          that.bulkInsertOrders();
        }
      });
    },
    async bulkInsertOrders() {
      this.startSetIsSubmitting({ bool: true, heading: "Uploading" });
      try {
        const orders = await this.startCreateBatchOrder(this.data);
        const updates = {
          _id: this.active._id,
          bulkItems: [
            ...this.active.bulkItems,
            ...orders.map(order => order._id)
          ]
        };
        await this.startUpdateBulkOrder(updates);
        this.startSetIsSubmitting({ bool: false });
        this.emitToggleImport();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorders-import {
  @include grid($cols: 100%, $row-gap: 10px);
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  width: 90vw;
  max-width: 500px;
}
.bulkorders-import--step {
  @include grid(
    $cols: 40px 1fr,
    $rows: 40px 1fr,
    $row-gap: 5px,
    $col-gap: 15px,
    $align: center
  );
  border-radius: 8px;
  padding: 10px;
  color: $dark-blue;
  grid-template-areas:
    "num head"
    "subhead subhead"
    "button button";
  &:hover {
    background-color: $powder;
  }
  > :nth-child(1) {
    grid-area: num;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-weight: bold;
    font-size: $text-md;
    background-color: $pistachio;
    border: 2px solid $green;
    @include flex($just: center, $align: center);
  }
  > :nth-child(2) {
    grid-area: head;
  }
  > :nth-child(3) {
    grid-area: subhead;
  }
  > :nth-child(4) {
    grid-area: button;
  }
}
</style>