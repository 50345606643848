<template>
  <section class="confirm-details">
    <div class="flex__between">
      <h3 class="text__center">Final Details</h3>
      <span
        class="text__red text__bold pointer"
        @click="toggleEditing"
      >{{ editing ? "Cancel" : "Edit" }}</span>
    </div>
    <p v-if="editing">You must click "submit" below to save any changes made to this data.</p>
    <label
      v-if="showThankYouNote"
      :class="[errors.thankYouNote ? 'form--error' : null]"
      class="col"
    >
      <span>Thank You Card</span>
      <span
        v-if="!editing"
        class="pre-wrap"
      >{{ active.thankYouNote ? active.thankYouNote : "nothing provided" }}</span>
      <div v-else class="form--comments">
        <textarea v-model="form.thankYouNote" placeholder="Dear Mr. ..." rows="3" maxlength="500" />
        <canned @setMessage="setThankYou" />
      </div>
    </label>
    <form-error :message="errors.thankYouNote" />
    <label :class="[errors.billingMethod ? 'form--error': null]">
      <span>Billing Method*</span>
      <span v-if="!editing">{{ active.billingMethod }}</span>
      <select v-else v-model="form.billingMethod">
        <option value></option>
        <option value="Payroll">Payroll</option>
        <option value="Marketing Budget">Marketing Budget (if available)</option>
      </select>
    </label>
    <form-error :message="errors.billingMethod" />
    <label class="pos__rel billing" :class="[errors.billingUser ? 'form--error' : null]">
      <span class="text__bold">Bill To*</span>
      <span
        v-if="!editing"
      >{{ selectedBillingUser ? selectedBillingUser.displayName : "none selected" }}</span>
      <div v-else class="selected-user flex__between" @click="toggleUsers">
        <span>{{ selectedBillingUser ? selectedBillingUser.displayName : "none" }}</span>
        <font-awesome-icon v-if="!usersToggled" icon="caret-down" size="sm" />
        <font-awesome-icon v-if="usersToggled" icon="caret-up" size="sm" />
      </div>
      <div v-if="usersToggled" class="billing-search">
        <input type="text" v-model="search" placeholder="search users" />
        <div v-if="!users" class="flex__center">
          <p class="text__bold text__center">Loading Users</p>
        </div>
        <div v-else class="billto-list--container">
          <simplebar class="minor--overflow" data-simplebar-auto-hide="false">
            <div class="billto-list">
              <label class="billto-list--item" v-for="user in filteredBillingUsers" :key="user._id">
                <input
                  type="radio"
                  v-model="form.billingUser"
                  :value="user._id"
                  @change="toggleUsers"
                />
                <span>{{ user.email }}</span>
              </label>
            </div>
          </simplebar>
        </div>
      </div>
    </label>
    <form-error :message="errors.billingUser" />
    <label>
      <span class="text__bold">Requested Delivery Date</span>
      <span v-if="!editing">{{ cleanDate(active.deliveryDate) }}</span>
      <input
        v-else
        type="date"
        v-mask="'####-##-##'"
        placeholder="YYYY-MM-DD"
        v-model="form.deliveryDate"
      />
    </label>
    <label class="col">
      <span class="text__bold">Order Comments</span>
      <span
        v-if="!editing"
        class="pre-wrap"
      >{{ active.comments ? active.comments : "no comments provided" }}</span>
      <div v-else class="form--comments">
        <textarea v-model="form.comments" rows="4" />
        <canned v-if="editing" @setMessage="setComments" />
      </div>
    </label>
    <div class="flex__between" v-if="editing">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Submit</button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  props: ["active"],
  data() {
    return {
      editing: false,
      isValidated: true,
      usersToggled: false,
      form: {
        _id: this.active ? this.active._id : null,
        billingMethod: this.active ? this.active.billingMethod : null,
        billingUser: this.active ? this.active.billingUser : null,
        billingName: this.active ? this.active.billingName : null,
        billingEmail: this.active ? this.active.billingEmail : null,
        thankYouNote: this.active ? this.active.thankYouNote : null,
        //   useAwardBalance: this.active ? this.active.useAwardBalance : false,
        //   awardAmountUsed: this.active ? this.active.awardAmountUsed : null,
        //   balanceDue: this.active ? this.active.balanceDue : null,
        deliveryDate: this.active ? this.active.deliveryDate : null,
        comments: this.active ? this.active.comments : null
      },
      errors: {
        billingMethod: null,
        billingUser: null,
        thankYouNote: null
        // orderItemsError: null
      },
      users: null,
      search: ""
    };
  },
  computed: {
    ...mapGetters(["getUserDetails", "getProducts"]),
    showThankYouNote() {
      return this.active
        ? this.active.orderItems.filter(item => item.isInventoried).length > 0
        : false;
    },
    filteredUsers() {
      if (!this.users) return null;
      const fullAccess = ["admin", "super-admin", "asm"];
      if (fullAccess.includes(this.getUserDetails.permission)) {
        return this.users;
      }
      return this.users.filter(item => {
        return item.delegatedUsers.includes(this.getUserDetails._id);
        // return (
        //   this.getUserDetails.delegatedUsers.filter(
        //     user => user._id === item._id
        //   ).length > 0
        // );
      });
    },
    filteredBillingUsers() {
      if (!this.users) return null;
      if (!this.search) return this.filteredUsers;
      return this.filteredUsers.filter(user => {
        return user.displayName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    selectedBillingUser() {
      if (!this.users) return null;
      if (!this.form.billingUser) return null;
      return this.filteredUsers.filter(
        user => user._id === this.form.billingUser
      )[0];
    }
    //  getAwardBal() {
    //    if (!this.form.billingUser) return 0;
    //    if (!this.users) return 0;
    //    return this.users.filter(user => {
    //      return user._id === this.form.billingUser;
    //    })[0].awardBalance;
    //  },
    // totalCost() {
    //   if (!this.active) return 0;
    //   if (!this.active.orderItems || this.active.orderItems.length <= 0)
    //     return 0;
    //   return this.active.orderItems
    //     .map(item => {
    //       const productCost = this.getProducts.filter(
    //         product => item.product === product._id
    //       )[0].approxValue;
    //       return item.quantity * productCost;
    //     })
    //     .reduce((a, b) => {
    //       return a + b;
    //     }, 0);
    // }
    //  balanceDueCalc() {
    //    if (this.form.useAwardBalance && this.getAwardBal) {
    //      const due = this.active.totalCost - this.getAwardBal;
    //      return due > 0 ? due : 0;
    //    }
    //    return this.active.balanceDue;
    //  },
    //  showRespaIssue() {
    //    if (this.active.recipientType === "Internal Employee") return false;
    //    if (this.active.coRecipientName) {
    //      return this.totalCost > 50;
    //    } else {
    //      return this.totalCost > 25;
    //    }
    //  }
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "loadDelegates",
      "startUpdateOrder"
    ]),
    async submit() {
      this.validateForm();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        this.form.billingUser = this.selectedBillingUser._id;
        this.form.billingName = this.selectedBillingUser.displayName;
        this.form.billingEmail = this.selectedBillingUser.email;
        //   this.form.awardAmountUsed = this.active.totalCost - this.balanceDueCalc;
        //   this.form.balanceDue = this.balanceDueCalc;
        //   const hfTicketId = await this.createOrderTicket({
        //     form: this.form,
        //     active: this.active,
        //     showRespaIssue: this.showRespaIssue
        //   });
        //   this.form.hfTicketId = hfTicketId;
        await this.startUpdateOrder(this.form);
        //   await this.updateUser();
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
        //   createSuccess("Order was Submitted!");
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.billingMethod =
        this.form.billingMethod === "" || !this.form.billingMethod
          ? "Please select a billing method"
          : null;
      this.errors.billingUser = !this.form.billingUser
        ? "Please select a person to bill to"
        : null;
      this.errors.thankYouNote =
        this.showThankYouNote && this.form.thankYouNote.length <= 0
          ? "Please input a thank you message"
          : null;
      // this.errors.orderItemsError =
      //   !this.active.orderItems || this.active.orderItems.length <= 0
      //     ? "You must choose at least one product for this order"
      //     : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    toggleEditing() {
      this.editing = !this.editing;
    },
    toggleUsers() {
      this.search = "";
      this.usersToggled = !this.usersToggled;
    },
    setComments(e) {
      this.form.comments = e.message;
    },
    setThankYou(e) {
      this.form.thankYouNote = e.message;
    },
    cleanDate(date) {
      if (!date) return "none selected";
      return moment(date).format("MM-DD-YYYY");
    }
  },
  async mounted() {
    try {
      this.users = await this.loadDelegates();
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-details {
  background-color: lighten($sky, 5%);
  padding: 5px;
  border: 1px solid $sky;
  border-radius: 3px;
  @include grid($cols: 1fr, $row-gap: 12px);
  label {
    @include grid($cols: 1fr 1fr, $col-gap: 5px);
    span:nth-child(1) {
      font-weight: bold;
      text-align: left;
    }
  }
}
.billto-list--container {
  padding: 8px 5px;
  border-radius: 3px;
}
.minor--overflow {
  height: 175px;
}
.selected-user {
  border: 1px solid $dark-blue;
  border-radius: 3px;
  padding: 10px;
  height: 2.75rem;
}
.billing-search {
  @include pos-abs(
    $pos: absolute,
    $top: calc(100% + 15px),
    $left: null,
    $right: 0,
    $width: 100%,
    $z: 10
  );
  @include grid($rows: 40px 1fr, $row-gap: 10px);
  background-color: $sky;
  padding: 10px;
  border-radius: 5px;
  @include shadow;
  height: 250px;
}
.billto-list {
  @include grid($cols: 1fr, $row-gap: 5px, $col-gap: 10px);
}
.billto-list label {
  @include grid($cols: 1fr 7fr, $col-gap: 10px, $align: center);
  padding: 5px 8px;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: lighten($sky, 10%);
  }
}
label.col {
  grid-template-columns: 1fr;
  margin-bottom: 20px;
}
@media (min-width: $sm) {
  .billto-list label {
    grid-template-columns: 1fr 7fr;
  }
}
@media (min-width: $md) {
}
</style>