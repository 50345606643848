<template>
  <li class="bulkorders-list--item pos__rel">
    <input type="checkbox" class="pointer" @click="checkToggled" :checked="isSelected" />
    <p class="text__overflow">{{ order.recipientName }} ({{ order.recipientType }})</p>
    <div class="flex__center pointer text__green pos__rel" @click="setActiveOrder">
      <Hover text="edit this order" class="hover" />
      <font-awesome-icon icon="edit" size="1x" />
    </div>
    <div class="flex__center pointer text__red pos__rel" @click="toggleDeleting">
      <Hover text="delete this order" class="hover" />
      <font-awesome-icon icon="trash" size="1x" />
    </div>
    <span class="text__bold">${{ order.totalCost.toFixed(2) }}</span>
    <div class="bulkitem-status flex__center">
      <div class="flex__center pos__rel">
        <Hover :text="statusIconHover" class="hover" :background="statusIconHoverColor" />
        <font-awesome-icon :icon="statusIcon" size="lg" :class="statusIconColor" />
      </div>
    </div>
    <delete v-if="deleting">
      <DeleteBulkItem
        v-if="deleting"
        :active="order"
        :activeBulk="bulk"
        @deleted="toggleDeleting"
        @toggleDeleting="toggleDeleting"
      />
    </delete>
  </li>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Hover from "./Admin/Hover";
import DeleteBulkItem from "./DeleteBulkItem";
export default {
  props: ["order", "bulk"],
  components: {
    Hover,
    DeleteBulkItem
  },
  data() {
    return {
      deleting: false
    };
  },
  computed: {
    ...mapGetters(["getBulkUpdateSelection"]),
    isSelected() {
      return this.getBulkUpdateSelection.includes(this.order._id);
    },
    showThankYouNote() {
      return this.order
        ? this.order.orderItems.filter(item => item.isInventoried).length > 0
        : false;
    },
    isValid() {
      let isValid = [];
      this.order.billingMethod === "" || !this.order.billingMethod
        ? isValid.push("Add billing method")
        : null;
      this.order.billingUser === "" || !this.order.billingUser
        ? isValid.push("Add billing user")
        : null;
      this.showThankYouNote && this.order.thankYouNote.length <= 0
        ? isValid.push("Add thank you")
        : null;
      !this.order.orderItems || this.order.orderItems.length <= 0
        ? isValid.push("Add products")
        : null;
      return isValid;
    },
    statusIcon() {
      return this.isValid.length <= 0 ? "check" : "exclamation";
    },
    statusIconColor() {
      return this.isValid.length <= 0 ? "text__green" : "text__red";
    },
    statusIconHover() {
      return this.isValid.length <= 0
        ? "Ready to order"
        : this.isValid.join("\n");
    },
    statusIconHoverColor() {
      return this.isValid.length <= 0 ? "bg__pine" : "bg__red";
    }
  },
  methods: {
    ...mapActions(["startSetActiveOrder", "startChangeBulkUpdateSelection"]),
    checkToggled(evt) {
      this.startChangeBulkUpdateSelection({
        status: evt.target.checked,
        _id: this.order._id
      });
    },
    setActiveOrder() {
      this.startSetActiveOrder(this.order._id);
    },
    toggleDeleting() {
      this.deleting = !this.deleting;
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorders-list--item {
  padding: 10px 10px;
  border-bottom: 1px solid $pistachio;
  @include grid($cols: 20px 1fr 20px 20px 50px, $col-gap: 10px, $align: center);
  &:nth-child(even) {
    background-color: $powder;
  }
}
.hover {
  display: none;
}
.flex__center:hover .hover {
  display: flex;
}
.bulkitem-status {
  @include pos-abs($left: calc(100% + 10px), $height: 100%, $z: 2);
}
@media (min-width: $sm) {
  .bulkorders-list--item {
    grid-column-gap: 20px;
  }
}
</style>