<template>
  <section class="modal" @click.self="emitToggleProducts">
    <section class="bulkitem-sidebar shadow pos__rel">
      <div class="bulkitem-sidebar--exit flex__center pointer" @click="emitToggleProducts">
        <font-awesome-icon icon="times" size="lg" />
      </div>
      <div v-if="!productSelected" class="bulkitem-sidebar--grid">
        <h2>Add Products</h2>
        <div class="search--bar">
          <input type="text" v-model="search" />
          <div class="btn__search">
            <font-awesome-icon icon="search" size="lg" />
          </div>
        </div>
        <simplebar class="sidebar--overflow" data-simplebar-auto-hide="false">
          <div class="bulkitem-sidebar__grid">
            <Product
              v-for="product in filteredProducts"
              :key="product._id"
              :product="product"
              @toggleProductSelected="toggleProductSelected"
            />
          </div>
        </simplebar>
      </div>
      <Cust v-else @toggleProductSelected="toggleProductSelected" :product="activeProduct" />
      <Cart v-if="!productSelected" />
    </section>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Product from "./Product";
import Cust from "./Cust";
import Cart from "./Cart";
export default {
  props: ["active", "activeBulk"],
  components: {
    Product,
    Cust,
    Cart
  },
  data() {
    return {
      search: "",
      productSelected: false,
      activeProduct: null
    };
  },
  computed: {
    ...mapGetters(["getLiveProducts"]),
    filteredProducts() {
      if (!this.search) return this.getLiveProducts;
      return this.getLiveProducts.filter(product => {
        return (
          product.name.toLowerCase().includes(this.search.toLowerCase()) ||
          product.tags.join(", ").includes(this.search.toLowerCase())
        );
      });
    }
  },
  methods: {
    ...mapActions(["startUnsetActiveOrder"]),
    emitToggleProducts() {
      this.$emit("toggleProducts");
    },
    toggleProductSelected(product) {
      this.productSelected = !this.productSelected;
      this.setActiveProduct(product);
    },
    setActiveProduct(product) {
      this.activeProduct = product;
    }
  }
};
</script>

<style scoped lang="scss">
$dark-frost: darken($sky, 35%);
.bulkitem-sidebar {
  @include pos-abs(
    $pos: fixed,
    $top: 0,
    $left: null,
    $right: 0,
    $width: calc(100% - 40px),
    $height: 100vh,
    $z: 15
  );
  max-width: 450px;
  background-color: $sky;
}
.bulkitem-sidebar--grid {
  padding: 25px;
  padding-right: 15px;
}
.bulkitem-sidebar--exit {
  @include pos-abs($pos: absolute, $top: 3px, $left: null, $right: 100%);
  background-color: $dark-frost;
  padding: 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: $sky;
}
.sidebar--overflow {
  padding: 10px 0;
  padding-right: 10px;
  margin: 15px 0;
  height: calc(100vh - 170px);
}
.bulkitem-sidebar__grid {
  @include grid($cols: 1fr, $row-gap: 10px, $col-gap: 10px);
}
</style>