<template>
  <div class="bulkitem-sidebar--product pointer" @click="emitToggleProductSelected">
    <div class="flex__center">
      <img :src="product.images[0]" :alt="`${product.name} image`" width="100%" height="100%" />
    </div>
    <p class="text__bold">{{ product.name }}</p>
    <p>
      <span class="text__bold mr-10">Cost:</span>
      <span>${{ product.loCost.toFixed(2) }}</span>
    </p>
    <div class="flex__right">
      <span class="text__peacock" @click="emitToggleProductSelected">
        View
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product"],
  methods: {
    emitToggleProductSelected() {
      this.$emit("toggleProductSelected", this.product);
    }
  }
};
</script>

<style scoped lang="scss">
.bulkitem-sidebar--product {
  background-color: lighten($sky, 10%);
  padding: 15px;
  @include grid($cols: 1fr, $row-gap: 10px);
}
img {
  width: 150px;
  height: 150px;
}
</style>