<template>
  <section class="bulkitem-sidebar--cart" :class="[open ? 'bulkitem-sidebar--cart__open' : null]">
    <div
      class="flex__center bulkitem-sidebar--slide pointer"
      :class="[open ? 'bulkitem-sidebar--slide__open' : null]"
    >
      <div class="scroll flex__center" @click="setOpen">
        <font-awesome-icon :icon="scrollIcon" size="sm" />
      </div>
    </div>
    <div class="flex__between total pointer" @click="setOpen">
      <font-awesome-icon icon="shopping-cart" size="2x" class="icon text__powder" />
      <p class="text__bold text__md text__white">${{ totalCost.toFixed(2) }}</p>
    </div>
    <div class="flex__right finish">
      <button class="btn btn__sm btn__green" @click="addBulkProducts">Submit</button>
    </div>
    <simplebar class="sidebar--overflow" data-simplebar-auto-hide="false">
      <div class="bulkitem-sidebar__grid">
        <div
          class="bulkitem__product"
          v-for="(product, index) in getBulkProductsTemplate"
          :key="`${index}-${product.product}`"
        >
          <div class="pointer" @click="deleteOrderItem">
            <font-awesome-icon icon="times" size="lg" class="icon" />
          </div>
          <img :src="productImg(product.product)" :alt="`${product.productName}`" />
          <div class="flex__col">
            <p class="text__bold text__overflow">{{ product.productName }}</p>
            <p>Qty: {{ product.quantity }}</p>
          </div>
        </div>
      </div>
    </simplebar>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapGetters([
      "getProducts",
      "getBulkUpdateSelection",
      "getBulkProductsTemplate"
    ]),
    scrollIcon() {
      return this.open ? "chevron-down" : "chevron-up";
    },
    totalCost() {
      return this.getBulkProductsTemplate.reduce((a, b) => {
        return (a = a + b.quantity * b.loCost);
      }, 0);
    }
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startDeleteBulkProductsTemplate",
      "startUpdateBatchProducts"
    ]),
    async deleteOrderItem(product) {
      try {
        this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
        await this.startDeleteBulkProductsTemplate(product);
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    setOpen() {
      this.open = !this.open;
    },
    productImg(id) {
      return this.getProducts.filter(product => product._id === id)[0]
        .images[0];
    },
    async addBulkProducts() {
      if (!this.getBulkProductsTemplate) return null;
      this.startSetIsSubmitting({ bool: true, heading: "Adding" });
      try {
        await this.startUpdateBatchProducts({
          ids: this.getBulkUpdateSelection,
          products: this.getBulkProductsTemplate
        });
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.bulkitem-sidebar--cart {
  @include pos-abs($top: calc(100vh - 70px), $width: 100%, $z: 10);
  background-color: $sky;
  height: calc(100vh - 110px);
  transition: top 0.2s ease-in-out;
}
.bulkitem-sidebar--cart__open {
  top: calc(0vh + 110px);
}
.bulkitem-sidebar--slide {
  transition: transform 0.2s ease-in-out;
}
.bulkitem-sidebar--slide__open {
  transform: translateY(70px);
  .scroll {
    border-radius: 0px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
.scroll {
  padding: 2px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: $peacock;
}
.total {
  padding: 10px 15px;
  background-color: $peacock;
  border-bottom: 1px solid $sky;
}
.sidebar--overflow {
  margin: 15px;
  height: calc(100vh - 350px);
  padding: 0 15px;
}
.bulkitem__product {
  @include grid($cols: 25px 50px 1fr, $col-gap: 10px, $align: center);
  .icon {
    color: $maroon;
  }
  img {
    width: 50px;
    height: 50px;
  }
}
.finish {
  margin-top: 15px;
  padding: 10px 15px;
}
</style>