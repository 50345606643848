<template>
  <div class="bulkorderitem-exit">
    <h3 class="text__center text__white">Exit Batch Order?</h3>
    <p
      class="text__center text__white"
    >This batch of orders will not be submitted. You can return to edit this batch at any time by visiting your account page and clicking on the appropriate batch.</p>
    <p class="text__md text__center text__white">Proceed?</p>
    <div class="flex__between">
      <button class="btn btn__sm btn__green" @click="emitToggleExiting">No, Go Back</button>
      <router-link to="/" class="btn btn__sm btn__red">Yes, Exit</router-link>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    emitToggleExiting() {
      this.$emit("toggleExiting");
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorderitem-exit {
  @include grid($cols: 1fr, $row-gap: 10px);
}
</style>