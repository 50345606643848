<template>
  <div class="modal flex__center" @click.self="emitToggleConfirm">
    <div class="order-tile tile">
      <exit-button @toggleModal="emitToggleConfirm" />
      <simplebar class="modal--overflow" data-simplebar-auto-hide="false">
        <h2 class="text__center">Confirm Order</h2>
        <div class="order-tile--rows">
          <div class="order-row">
            <span class="text__bold">Total Cost:</span>
            <span>${{ totalCost.toFixed(2) }}</span>
          </div>
          <div class="order-row">
            <span class="text__bold">Order Count:</span>
            <span>{{ active.bulkItems.length }}</span>
          </div>
          <div class="order-row">
            <span class="text__bold">Balance Due:</span>
            <span>${{ totalCost.toFixed(2) }}</span>
          </div>
          <div class="order-row__comments">
            <span>Comments:</span>
            <simplebar v-if="!editing" class="minor--overflow" data-simplebar-auto-hide="false">
              <p
                class="pre-wrap text__normal"
              >{{ active.comments ? active.comments : "No comments provided for this batch" }}</p>
            </simplebar>
            <textarea v-else v-model="form.comments" rows="5"></textarea>
          </div>
          <p
            v-if="!canSubmit"
            class="text__center text__red text__bold"
          >Warning! This batch order cannot be submitted until all orders are ready to submit</p>
          <div v-if="!editing" class="flex__between mt-15">
            <button class="btn btn__frost" @click="toggleEditing">Edit</button>
            <button class="btn btn__green" @click="submit" :disabled="!canSubmit">Submit</button>
          </div>
          <div v-else class="flex__between mt-15">
            <button class="btn btn__red" @click="toggleEditing">Cancel</button>
            <button class="btn btn__green" @click="update">Save</button>
          </div>
        </div>
      </simplebar>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createSuccess } from "../../../libs/flash-messages";
export default {
  props: ["active", "orders", "canSubmit"],
  data() {
    return {
      editing: false,
      form: {
        _id: this.active ? this.active._id : null,
        comments: this.active ? this.active.comments : "",
        status: this.active ? this.active.status : false
      },
      users: null,
      search: ""
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    totalCost() {
      if (this.orders.length <= 0) {
        return 0;
      }
      let total = 0;
      this.orders.map(order => {
        let sum = order.orderItems.reduce((a, b) => {
          return (a = a + b.loCost * b.quantity);
        }, 0);
        total = total + (sum >= 0 ? sum : 0);
      });
      return total;
    },
    filteredUsers() {
      if (!this.users) return null;
      const fullAccess = ["admin", "super-admin", "asm"];
      if (fullAccess.includes(this.getUserDetails.permission)) {
        return this.users;
      }
      return this.users.filter(item => {
        return item.delegatedUsers.includes(this.getUserDetails._id);
      });
    },
    filteredBillingUsers() {
      if (!this.users) return null;
      if (!this.search) return this.filteredUsers;
      return this.filteredUsers.filter(user => {
        return user.displayName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    selectedBillingUser() {
      if (!this.users) return null;
      if (!this.form.billingUser) return null;
      return this.filteredUsers.filter(
        user => user._id === this.form.billingUser
      )[0];
    }
  },
  methods: {
    ...mapActions([
      "loadDelegates",
      "startSetIsSubmitting",
      "startSetSubLoading",
      "startUpdateBulkOrder",
      "submitBulkOrder"
    ]),
    emitToggleConfirm() {
      this.$emit("toggleConfirm", false);
    },
    async update() {
      this.startSetIsSubmitting({ bool: true, heading: "Sending" });
      try {
        await this.startUpdateBulkOrder(this.form);
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    submit() {
      if (!this.canSubmit) return;
      createSuccess(
        "Batch order submitted: it will take some time to submit all orders, please feel free to browse the site"
      );
      // this.startSetIsSubmitting({ bool: true, heading: "Sending" });
      this.startSetSubLoading(true);
      try {
        this.submitBulkOrder(this.active);
        this.form.status = true;
        this.update();
        // this.startSetIsSubmitting({ bool: false });
        this.$router.push("/");
      } catch (err) {
        // this.startSetIsSubmitting({ bool: false });
      }
    },
    toggleEditing() {
      this.editing = !this.editing;
    }
  },
  async mounted() {
    try {
      this.users = await this.loadDelegates();
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style scoped lang="scss">
.order-tile {
  width: 90%;
  max-width: 450px;
  position: relative;
  align-items: center;
  .order-row {
    align-items: center;
  }
}
.text__normal {
  font-weight: normal !important;
  text-align: right;
}
.modal--overflow {
  max-height: 500px;
  padding-right: 10px;
}
.order-tile--rows {
  @include grid($cols: 1fr, $row-gap: 10px);
}
.minor--overflow {
  height: 125px;
}
.order-row__comments {
  :nth-child(1) {
    font-weight: bold;
  }
  @include grid($cols: 1fr, $row-gap: 5px);
}
.btn:disabled {
  background: $cloud;
  color: darken($cloud, 25%);
  border: 2px solid darken($cloud, 25%);
}
</style>