<template>
  <div class="bulkorders-admin--add">
    <div class="pointer pos__rel">
      <Hover text="add order" class="hover" />
      <router-link :to="`/batch-order/${active._id}/batch-items/new`" class="link__none">
        <font-awesome-icon icon="plus" size="lg" class="icon" />
      </router-link>
    </div>
    <div class="pointer pos__rel" @click="emitToggleImport">
      <Hover text="import orders" class="hover" />
      <font-awesome-icon icon="file-import" size="lg" class="icon" />
    </div>
  </div>
</template>

<script>
import Hover from "./Hover";
export default {
  props: ["active"],
  components: {
    Hover
  },
  methods: {
    emitToggleImport() {
      this.$emit("toggleImport");
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorders-admin--add {
  @include grid($cols: 1fr 1fr, $col-gap: 15px);
}
.hover {
  display: none;
}
.pointer:hover .hover {
  display: flex;
}
.icon {
  color: $peacock;
}
</style>