<template>
  <section class="bulkorders-admin flex__between">
    <div class="admin-group flex__around">
      <div class="pointer pos__rel flex__center">
        <Hover :text="selectText" class="hover" />
        <input type="checkbox" class="pointer" @click="checkToggled" />
      </div>
      <BatchActions v-show="showBulk" :active="active" @toggleProducts="emitToggleProducts" />
      <Add :active="active" @toggleImport="emitToggleImport" />
    </div>
    <div class="pointer pos__rel" @click="emitToggleConfirm">
      <Hover text="confirm order" class="hover" />
      <span class="text__bold text__md">${{ totalCost.toFixed(2) }}</span>
      <font-awesome-icon icon="shopping-cart" size="lg" class="icon" />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BatchActions from "./BatchActions";
import Add from "./Add";
import Hover from "./Hover";
export default {
  props: ["active", "bulkItems"],
  components: {
    BatchActions,
    Add,
    Hover
  },
  computed: {
    ...mapGetters(["getBulkUpdateSelection"]),
    showBulk() {
      return this.getBulkUpdateSelection.length > 0;
    },
    selectText() {
      return this.bulkChecked ? "uncheck all" : "check all";
    },
    items() {
      return this.bulkItems.map(item => {
        return item._id;
      });
    },
    totalCost() {
      return this.bulkItems.reduce((a, b) => {
        return (a = a + b.totalCost);
      }, 0);
    }
  },
  methods: {
    ...mapActions(["startUpdateBulkUpdateSelection"]),
    emitToggleImport() {
      this.$emit("toggleImport");
    },
    emitToggleProducts() {
      this.$emit("toggleProducts");
    },
    checkToggled(evt) {
      if (evt.target.checked) {
        return this.startUpdateBulkUpdateSelection(this.items);
      } else {
        return this.startUpdateBulkUpdateSelection([]);
      }
    },
    emitToggleConfirm() {
      this.$emit("toggleConfirm");
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorders-admin {
  margin: 25px 5px 20px 5px;
  padding-bottom: 15px;
  border-bottom: 2px solid $peacock;
}
input {
  width: 1.4rem;
  height: 1.4rem;
}
.admin-group {
  @include grid($cols: 30px 1fr 1fr, $col-gap: 15px);
}
.hover {
  display: none;
}
.pointer:hover .hover {
  display: flex;
}
.icon {
  color: $pine;
  font-size: 1.75rem;
}
</style>