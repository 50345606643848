<template>
  <div class="bulkorderitem-delete">
    <h3 class="text__center text__white">Delete Batch Items?</h3>
    <p class="text__center text__powder">
      Be advised that you cannot undo this action.
      <br />
      <span class="text__bold text__powder">{{ toUpdate.length }}</span> Batch Items along with all products selected for them will be deleted.
    </p>
    <p class="text__md text__center text__white">Proceed?</p>
    <div class="flex__between">
      <button class="btn btn__sm btn__green" @click="emitToggleDeleting">No, Go Back</button>
      <button class="btn btn__sm btn__red" @click="deleteBulkItems">Yes, Delete</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["active", "toUpdate"],
  computed: {
    ...mapGetters(["getUserDetails"])
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startDeleteBatchOrder",
      "startUpdateBulkOrder",
      "startUpdateBulkUpdateSelection"
    ]),
    async deleteBulkItems() {
      //send bulkID along with toUpdate in object
      this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
      try {
        const data = {
          bulk: this.active._id,
          orders: this.toUpdate
        };
        await this.startDeleteBatchOrder(data);
        this.startUpdateBulkUpdateSelection([]);
        //   this.$emit("deleteSuccess");
        this.startSetIsSubmitting({ bool: false });
        this.emitToggleDeleting();
      } catch (err) {
        this.emitToggleDeleting();
        this.startSetIsSubmitting({ bool: false });
      }
    },
    emitToggleDeleting() {
      this.$emit("deleteMany");
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorderitem-delete {
  @include grid($cols: 1fr, $row-gap: 10px);
}
</style>