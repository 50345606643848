<template>
  <div class="bulkitem-sidebar--cust">
    <div class="flex__left">
      <span class="flex__left pointer" @click="emitToggleProductSelected">
        <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />back
      </span>
    </div>
    <simplebar class="cust--overflow" data-simplebar-auto-hide="false">
      <div class="cust__grid">
        <div class="flex__center">
          <img :src="product.images[0]" :alt="`${product.name} photo`" width="100%" height="100%" />
        </div>
        <p class="text__bold text__md">{{ product.name }}</p>
        <p>{{ product.description }}</p>
        <div class="cust__grid">
          <single-cust
            class="cust--list-item"
            @sendvalue="setValue"
            v-for="(cust, index) in product.customizations"
            :key="cust._id"
            :index="index"
            :cust="cust"
          />
        </div>
        <p class="form-error--message" v-if="!isValidated">
          You must provide a valid selection for
          <i>every</i> customization
        </p>
        <div class="add__cust--confirm flex__between">
          <select v-model="form.quantity">
            <option v-for="num in 25" :key="num" :value="num">{{ num }}</option>
          </select>
          <p class="text__pine text__bold text__md">${{ (form.quantity * form.loCost).toFixed(2) }}</p>
        </div>
        <div class="flex__right">
          <button class="btn btn__sm btn__green" @click="submit">Submit</button>
        </div>
      </div>
    </simplebar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["product"],
  data() {
    return {
      isValidated: true,
      form: {
        product: this.product ? this.product._id : null,
        productName: this.product ? this.product.name : null,
        isInventoried: this.product ? this.product.isInventoried : false,
        loCost: this.product ? this.product.loCost : null,
        itemCost:
          this.product && this.product.itemCost ? this.product.itemCost : 0,
        invoiceCost:
          this.product && this.product.invoiceCost
            ? this.product.invoiceCost
            : 0,
        quantity: 1,
        customizations: []
      },
      customizationCount: this.product ? this.product.customizations.length : 0
    };
  },
  watch: {
    product: {
      deep: true,
      handler: function() {
        this.isValidated = true;
        this.form = {
          product: this.product ? this.product._id : null,
          productName: this.product ? this.product.name : null,
          isInventoried: this.product ? this.product.isInventoried : false,
          loCost: this.product ? this.product.loCost : null,
          itemCost:
            this.product && this.product.itemCost ? this.product.itemCost : 0,
          invoiceCost:
            this.product && this.product.invoiceCost
              ? this.product.invoiceCost
              : 0,
          quantity: 1,
          customizations: []
        };
        this.customizationCount = this.product
          ? this.product.customizations.length
          : 0;
      }
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateBulkProductsTemplate"]),
    emitToggleProductSelected() {
      this.$emit("toggleProductSelected", null);
    },
    async submit() {
      this.validateForm();
      if (!this.isValidated) return null;
      //save order item and attach to bulk item
      this.startSetIsSubmitting({ bool: true, heading: "Adding" });
      try {
        await this.startUpdateBulkProductsTemplate(this.form);
        this.emitToggleProductSelected(null);
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      this.isValidated = this.form.quantity > 0 && this.isValidated;
      this.isValidated = this.form.quantity !== "" && this.isValidated;
      for (let i = 0; i < this.customizationCount; i += 1) {
        this.isValidated =
          this.form.customizations[i] !== "" &&
          typeof this.form.customizations[i] !== "undefined" &&
          this.isValidated;
      }
    },
    setValue(obj) {
      const index = this.form.customizations.findIndex(
        item => item.name === obj.name
      );
      const cust = { name: obj.name, option: obj.option };
      if (index >= 0) {
        this.form.customizations.splice(index, 1, cust);
      } else {
        this.form.customizations.push(cust);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.bulkitem-sidebar--cust {
  @include grid($cols: 1fr, $row-gap: 10px);
  padding: 10px 15px;
  img {
    width: 200px;
    height: 200px;
  }
}
select {
  max-width: 100px;
}
.cust--overflow {
  height: calc(100vh - 75px);
  padding-right: 15px;
}
.cust__grid {
  @include grid($cols: 1fr, $row-gap: 10px);
}
.cust--list-item {
  font-weight: bold;
}
</style>