<template>
  <div class="bulkorder-delete">
    <h3 class="text__center text__white">Delete Batch Order?</h3>
    <p
      class="text__center text__white"
    >Be advised that you cannot undo this action. The Batch Order along with all orders associated with it will be deleted.</p>
    <p class="text__md text__center text__white">Proceed?</p>
    <div class="flex__between">
      <button class="btn btn__sm btn__green" @click="emitToggleDeleting">No, Go Back</button>
      <button class="btn btn__sm btn__red" @click="deleteBulkOrder">Yes, Delete</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["active"],
  computed: {
    ...mapGetters(["getUserDetails"])
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startDeleteBulkOrder"]),
    async deleteBulkOrder() {
      //delete bulk-item and all associated order-items
      this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
      const form = {
        _id: this.active._id,
        requester: this.getUserDetails._id,
        requesterName: this.getUserDetails.displayName,
        requesterEmail: this.getUserDetails.email,
        requester2Email: this.getUserDetails.email
      };
      try {
        await this.startDeleteBulkOrder(form);
        this.$router.push("/");
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    emitToggleDeleting() {
      this.$emit("toggleDeleting");
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorder-delete {
  @include grid($cols: 1fr, $row-gap: 10px);
}
</style>