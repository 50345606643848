<template>
  <li class="add-product--item" @click="emitToggleProductSelected">
    <div class="flex__center">
      <img :src="product.images[0]" :alt="`${product.name} image`" width="100%" height="100%" />
    </div>
    <p class="text__bold text__md">{{ product.name }}</p>
    <p>
      <span class="mr-10 text__bold">Cost</span>
      <span>${{ product.loCost.toFixed(2) }}</span>
    </p>
    <!-- <p>
      <span class="mr-10 text__bold">Respa</span>
      <span>{{ product.respaStatus }}</span>
    </p> -->
    <p class="text__right">
      Add
      <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
    </p>
  </li>
</template>

<script>
export default {
  props: ["product"],
  methods: {
    emitToggleProductSelected() {
      this.$emit("toggleProductSelected", this.product);
    }
  }
};
</script>

<style scoped lang="scss">
.add-product--item {
  background-color: lighten($sky, 5%);
  padding: 15px;
  @include grid($cols: 1fr, $row-gap: 8px);
  border: 1px solid $sky;
  border-radius: 5px;
}
img {
  width: 75px;
  height: 75px;
}
</style>