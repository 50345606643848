<template>
  <div class="bulkorderitem-delete">
    <h3 class="text__center text__white">Delete Batch Item?</h3>
    <p
      class="text__center text__white"
    >Be advised that you cannot undo this action. The Batch Item along with all products selected for this Batch Item will be deleted.</p>
    <p class="text__md text__center text__white">Proceed?</p>
    <div class="flex__between">
      <button class="btn btn__sm btn__green" @click="emitToggleDeleting">No, Go Back</button>
      <button class="btn btn__sm btn__red" @click="deleteBulkItem">Yes, Delete</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["active", "activeBulk"],
  computed: {
    ...mapGetters(["getUserDetails"])
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startDeleteOrder",
      "startUnsetActiveOrder",
      "startUpdateBulkOrder"
    ]),
    async deleteBulkItem() {
      //delete bulk-item and all associated order-items
      this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
      const form = {
        _id: this.active._id,
        requester: this.getUserDetails._id,
        requesterName: this.getUserDetails.displayName,
        requesterEmail: this.getUserDetails.email,
        requester2Email: this.getUserDetails.email,
      };
      const bulkForm = {
        _id: this.activeBulk._id,
        bulkItems: this.activeBulk.bulkItems.filter(
          item => item !== this.active._id
        )
      };
      try {
        await this.startDeleteOrder(form);
        await this.startUpdateBulkOrder(bulkForm);
        this.startUnsetActiveOrder(null);
        this.$emit("deleted");
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    emitToggleDeleting() {
      this.$emit("toggleDeleting");
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorderitem-delete {
  @include grid($cols: 1fr, $row-gap: 10px);
}
</style>