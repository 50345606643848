<template>
  <div class="confirm-products--add__cust">
    <div class="flex__center">
      <img :src="active.images[0]" width="75px" height="75px" :alt="`${active.name} image`" />
    </div>
    <p class="text__bold text__center">{{ active.name }}</p>
    <div class="add__cust--cust">
      <div class="tile-line">
        <p class="text__bold">Customizations</p>
        <hr />
      </div>
      <p class="form-error--message" v-if="!isValidated">
        You must provide a valid selection for
        <i>every</i> customization
      </p>
      <div
        v-if="!active.customizations || active.customizations.length <= 0"
        class="padding__md flex__center"
      >
        <p class="text__center text__bold">No Customizations for this product</p>
      </div>
      <simplebar v-else class="cust--overflow" data-simplebar-auto-hide="false">
        <div class="cust--list">
          <single-cust
            class="cust--list-item"
            @sendvalue="setValue"
            v-for="(cust, index) in active.customizations"
            :key="cust._id"
            :index="index"
            :cust="cust"
          />
        </div>
      </simplebar>
    </div>
    <div class="add__cust--confirm flex__between">
      <label>
        Qty
        <select v-model="form.quantity">
          <option v-for="num in 25" :key="num" :value="num">{{ num }}</option>
        </select>
      </label>
      <p class="text__pine text__bold text__md">${{ (form.quantity * form.loCost).toFixed(2) }}</p>
    </div>
    <div class="flex__between">
      <button class="btn btn__sm btn__red" @click="emitToggleProductSelected">Go Back</button>
      <button class="btn btn__sm btn__green" @click="submit">Add</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["active", "activeOrder"],
  data() {
    return {
      isValidated: true,
      form: {
        order: this.activeOrder ? this.activeOrder._id : null,
        product: this.active ? this.active._id : null,
        productName: this.active ? this.active.name : null,
        isInventoried: this.active ? this.active.isInventoried : false,
        loCost: this.active ? this.active.loCost : null,
        itemCost:
          this.active && this.active.itemCost ? this.active.itemCost : 0,
        invoiceCost:
          this.active && this.active.invoiceCost ? this.active.invoiceCost : 0,
        quantity: 1,
        customizations: []
      },
      customizationCount: this.active ? this.active.customizations.length : 0
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startCreateOrderItem"]),
    emitToggleProductSelected() {
      this.$emit("toggleProductSelected", null);
    },
    async submit() {
      this.validateForm();
      if (!this.isValidated) return null;
      //save order item and attach to bulk item
      this.startSetIsSubmitting({ bool: true, heading: "Adding" });
      try {
        await this.startCreateOrderItem(this.form);
        this.emitToggleProductSelected(null);
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      this.isValidated = this.form.quantity > 0 && this.isValidated;
      this.isValidated = this.form.quantity !== "" && this.isValidated;
      for (let i = 0; i < this.customizationCount; i += 1) {
        this.isValidated =
          this.form.customizations[i] !== "" &&
          typeof this.form.customizations[i] !== "undefined" &&
          this.isValidated;
      }
    },
    setValue(obj) {
      const index = this.form.customizations.findIndex(
        item => item.name === obj.name
      );
      const cust = { name: obj.name, option: obj.option };
      if (index >= 0) {
        this.form.customizations.splice(index, 1, cust);
      } else {
        this.form.customizations.push(cust);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-products--add__cust {
  background-color: lighten($frost, 5%);
  border: 1px solid $sky;
  border-radius: 5px;
  @include grid($cols: 1fr, $row-gap: 10px);
  padding: 15px;
}
.add__cust--cust {
  @include grid($cols: 1fr, $row-gap: 10px);
}
.cust--overflow {
  max-height: 150px;
}
.cust--list {
  @include grid($cols: 1fr, $row-gap: 8px);
}
.cust--list-item {
  @include flex($just: space-between, $align: center, $wrap: wrap);
  font-weight: bold;
}
.add__cust--confirm label {
  @include grid($cols: 1fr 1fr, $col-gap: 5px, $align: center);
  font-weight: bold;
}
</style>