<template>
  <div class="bulkorders-hover flex__center">
    <div class="bulkorders-hover--content" :class="background">
      <font-awesome-icon icon="caret-up" size="2x" class="icon" :class="background" />
      <div class="text__center pre-wrap" :class="background">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "background"],
  computed: {}
};
</script>

<style scoped lang="scss">
.bulkorders-hover {
  @include pos-abs($top: 95%, $left: 0, $width: 100%);
}
.bulkorders-hover--content {
  @include grid($cols: 1fr, $just: center);
  .icon {
    color: $black;
    margin-bottom: -12px;
  }
  .text__center {
    background-color: $black;
    padding: 8px 10px;
    border-radius: 10px;
    color: #fff;
    font-size: $text-regular;
    white-space: nowrap;
  }
}
.bg__pine {
  .icon {
    color: $pine;
  }
  .text__center {
    background-color: $pine;
  }
}
.bg__red {
  .icon {
    color: $maroon;
  }
  .text__center {
    background-color: $maroon;
  }
}
</style>