<template>
  <section class="confirm-details">
    <div class="flex__between">
      <h3 class="text__center">Contact Info</h3>
      <span
        class="text__red text__bold pointer"
        @click="toggleEditing"
      >{{ editing ? "Cancel" : "Edit" }}</span>
    </div>
    <p v-if="editing">You must click "submit" below to save any changes made to this data.</p>
    <label :class="[errors.shippingName ? 'form--error' : null]" l>
      Shipping Name*
      <span v-if="!editing" class="text__right">{{ active.shippingName }}</span>
      <input v-else type="text" v-model="form.shippingName" placeholder="first and last" />
    </label>
    <form-error v-if="editing" :message="errors.shippingName" />
    <label>
      Shipping Address*
      <div v-if="!editing">
        <span class="text__overflow">{{ active.shippingAddress.street }}</span>
        <br />
        <span>{{ active.shippingAddress.city }}, {{ active.shippingAddress.state }}</span>
        <br />
        <span>{{ active.shippingAddress.zip }}</span>
      </div>
      <div v-else class="address__block" :class="[errors.shippingAddress ? 'form--error' : null]">
        <input
          type="text"
          v-model="form.shippingAddress.street"
          class="address__street"
          placeholder="street"
        />
        <input
          type="text"
          v-model="form.shippingAddress.city"
          class="address__city"
          placeholder="city"
        />
        <select v-model="form.shippingAddress.state" class="address__state">
          <option value>State</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
        <input
          type="text"
          v-model="form.shippingAddress.zip"
          v-mask="'#####'"
          class="address__zip"
          placeholder="zip"
        />
      </div>
    </label>
    <form-error v-if="editing" :message="errors.shippingAddress" />
    <label :class="[errors.shippingPhone ? 'form--error' : null]">
      Shipping Phone*
      <span v-if="!editing">{{ active.shippingPhone }}</span>
      <input
        v-else
        type="tel"
        v-model="form.shippingPhone"
        v-mask="'(###) ###-####'"
        placeholder="(123) 456-7890"
      />
    </label>
    <form-error v-if="editing" :message="errors.shippingPhone" />
    <div class="flex__between" v-if="editing">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Submit</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["active"],
  data() {
    return {
      editing: false,
      isValidated: true,
      form: {
        _id: this.active ? this.active._id : null,
        shippingName: this.active ? this.active.shippingName : null,
        shippingAddress: {
          street: this.active ? this.active.shippingAddress.street : null,
          city: this.active ? this.active.shippingAddress.city : null,
          state: this.active ? this.active.shippingAddress.state : null,
          zip: this.active ? this.active.shippingAddress.zip : null
        },
        shippingPhone: this.active ? this.active.shippingPhone : null
      },
      errors: {
        shippingName: null,
        shippingAddress: null,
        shippingPhone: null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateOrder"]),
    async submit() {
      // if (!this.canEdit) return;
      this.validateForm();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Submitting" });
      try {
        await this.startUpdateOrder(this.form);
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.shippingName =
        this.form.shippingName === "" ? "Please enter a shipping name" : null;
      if (
        this.form.shippingAddress.street === "" ||
        this.form.shippingAddress.city === "" ||
        this.form.shippingAddress.state === "" ||
        this.form.shippingAddress.zip === "" ||
        this.form.shippingAddress.zip.length < 5
      ) {
        this.errors.shippingAddress =
          "Please enter a full value for all parts of the address";
      } else {
        this.errors.shippingAddress = null;
      }
      this.errors.shippingPhone =
        this.form.shippingPhone === "" ? "Please enter a phone number" : null;
      this.errors.shippingPhone =
        this.form.shippingPhone.length < 14
          ? "Please enter all digits of the phone number"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    toggleEditing() {
      this.editing = !this.editing;
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-details {
  background-color: lighten($sky, 5%);
  padding: 5px;
  border: 1px solid $sky;
  border-radius: 3px;
}
</style>