<template>
  <div class="bulkorders-admin--actions">
    <div class="pointer pos__rel" @click="emitToggleProducts">
      <Hover text="add products" class="hover" />
      <font-awesome-icon icon="search-plus" size="lg" class="icon" />
    </div>
    <div class="pointer pos__rel" @click="toggleDelete">
      <Hover text="delete orders" class="hover" />
      <font-awesome-icon icon="trash" size="lg" class="icon" />
    </div>
    <delete v-if="deleteToggled">
      <DeleteBulkItems
        :active="active"
        :toUpdate="getBulkUpdateSelection"
        @deleteMany="toggleDelete"
      />
    </delete>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeleteBulkItems from "../DeleteBulkItems";
import Hover from "./Hover";
export default {
  props: ["active"],
  components: {
    Hover,
    DeleteBulkItems
  },
  data() {
    return {
      deleteToggled: false
    };
  },
  computed: {
    ...mapGetters(["getBulkUpdateSelection"])
  },
  methods: {
    toggleDelete() {
      this.deleteToggled = !this.deleteToggled;
    }, 
    emitToggleProducts() {
      this.$emit("toggleProducts");
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorders-admin--actions {
  @include grid($cols: 1fr 1fr, $col-gap: 15px);
}
.hover {
  display: none;
}
.pointer:hover .hover {
  display: flex;
}
.icon {
  color: $peacock;
}
</style>