<template>
  <section class="bulkorders-header">
    <section class="flex__between">
      <button class="btn btn__sm btn__red" @click="emitToggleDeleting">Delete</button>
      <button class="btn btn__sm btn__frost" @click="emitToggleExiting">Exit</button>
    </section>
    <h1 class="text__center padding__sm">{{ active.name }}: Orders</h1>
  </section>
</template>

<script>
export default {
  props: ["active"],
  methods: {
    emitToggleDeleting() {
      this.$emit("toggleDeleting");
    },
    emitToggleExiting() {
      this.$emit("toggleExiting");
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorders-header {
  padding: 10px 0;
}
</style>