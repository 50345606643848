<template>
  <ul class="bulkorders-list">
    <Order v-for="order in bulkItems" :key="order._id" :order="order" :bulk="bulk" />
        <li class="padding__md flex__center">
      <ul class="bulkorders-list--default">
        <p v-if="bulkItems.length <= 0" class="text__center text__md">No orders have been added to this batch yet.</p>
        <p v-else class="text__center text__md">Add more orders to this batch.</p>
        <router-link :to="`/batch-order/${bulk._id}/batch-items/new`" class="link__none">
          <li>
            <font-awesome-icon icon="plus" size="lg" />Add an Order
          </li>
        </router-link>
        <li @click="emitToggleImport">
          <font-awesome-icon icon="file-import" size="lg" />Import Orders
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import Order from "./Order";
export default {
  props: ["bulkItems", "bulk"],
  components: {
    Order
  },
    methods: {
    emitToggleImport() {
      this.$emit("toggleImport");
    }
  }
};
</script>

<style scoped lang="scss">
.bulkorders-list {
  margin-top: 15px;
  padding-right: 10px;
  @include grid($cols: 1fr);
}
.bulkorders-list--default {
  @include grid($cols: 1fr, $row-gap: 25px, $just: center);
  li {
    @include grid($cols: 25px 1fr, $col-gap: 15px, $align: center);
    cursor: pointer;
  }
}
</style>